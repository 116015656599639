import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  incident_types: [],
  incident_statuses: [],
  skill_types: [],
  beneficiaries: [],
  locations: [],
  activity_types: [],
  beneficiary_types: [],
  role_types: [],
  goals: []
};

const slice = createSlice({
  name: 'types',
  initialState,
  reducers: {
    getTypes(state, action) {
      state[action.payload.key] = action.payload.value;
    }
  }
});

export const reducer = slice.reducer;

async function getType(url, key, dispatch) {
  const response = await axios.get(url);
  const value = response.data.data;
  dispatch(
    slice.actions.getTypes({
      key,
      value
    })
  );
}

export const getTypes = () => async dispatch => {
  getType('api/incident_statuses', 'incident_statuses', dispatch);
  getType('api/incident_types', 'incident_types', dispatch);
  getType('api/skills', 'skill_types', dispatch);
  getType('api/beneficiaries?include=type', 'beneficiaries', dispatch);
  getType('api/locations', 'locations', dispatch);
  getType('api/activity_types', 'activity_types', dispatch);
  getType('api/beneficiary_types', 'beneficiary_types', dispatch);
  getType('api/roles', 'role_types', dispatch);
  getType('api/goals', 'goals', dispatch);
};

export const refreshType = (key, value) => async dispatch => {
  dispatch(
    slice.actions.getTypes({
      key,
      value
    })
  );
};

export const selectEvent = eventId => async dispatch => {
  dispatch(slice.actions.selectEvent({ eventId }));
};

export const updateEvent = (eventId, update) => async dispatch => {
  const response = await axios.post('/api/calendar/events/update', {
    eventId,
    update
  });

  dispatch(slice.actions.updateEvent(response.data));
};

export const deleteEvent = eventId => async dispatch => {
  await axios.post('/api/calendar/events/remove', {
    eventId
  });

  dispatch(slice.actions.deleteEvent({ eventId }));
};

export const selectRange = (start, end) => dispatch => {
  dispatch(
    slice.actions.selectRange({
      start: start.getTime(),
      end: end.getTime()
    })
  );
};

export const openModal = () => dispatch => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => dispatch => {
  dispatch(slice.actions.closeModal());
};

export default slice;

/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import Warning from '@material-ui/icons/Warning';
import {
  BarChart as BarChartIcon,
  User as UserIcon,
  Sliders,
  Award,
  Home,
  Users,
  Smile,
  Activity,
  Calendar,
  Shield
} from 'react-feather';
import NavItem from './NavItem';
import { useSelector } from 'src/store';
import { INSTITUTIONS } from 'src/constants';

const sections = isAdmin => {
  let sectionsArr = [];
  sectionsArr.push({
    subheader: 'Reports',
    items: [
      {
        title: 'Dashboard',
        icon: BarChartIcon,
        href: '/app/reports/dashboard'
      }
    ]
  });
  let managementArr = [
    {
      title: 'Users',
      icon: UserIcon,
      href: '/app/management/volunteers',
      items: !isAdmin
        ? undefined
        : [
            {
              title: 'All Users',
              icon: UserIcon,
              href: '/app/management/volunteers'
            },
            {
              title: 'Top Volunteers',
              icon: Shield,
              href: '/app/management/volunteers/topVolunteers'
            }
          ]
    }
  ];

  if (isAdmin) {
    managementArr.push({
      title: 'Beneficiaries',
      icon: Smile,
      href: '/app/management/beneficiaries'
    });
  }

  managementArr.push(
    {
      title: INSTITUTIONS,
      icon: Home,
      href: '/app/management/institutions'
    },
    {
      title: 'Teams',
      icon: Users,
      href: '/app/management/teams'
    },
    {
      title: 'Programmes',
      icon: Calendar,
      href: '/app/management/programmes'
    },
    {
      title: 'Activities',
      icon: Activity,
      href: '/app/management/activities'
    },
    // {
    //   title: 'Training',
    //   icon: Play,
    //   href: '/app/management/training'
    // },
    {
      title: 'Awards',
      icon: Award,
      href: '/app/management/awards'
    },
    {
      title: 'Incidents',
      icon: Warning,
      href: '/app/management/incidents'
    }
  );
  if (isAdmin) {
    managementArr.push({
      title: 'Types',
      icon: Sliders,
      href: '/app/management/types'
    });
  }

  sectionsArr.push({
    subheader: 'Management',
    items: managementArr
  });

  return sectionsArr;
};

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const profile = useSelector(state => state.profile);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            {/* <RouterLink to="/app/account"> */}
            <Avatar
              alt="User"
              className={classes.avatar}
              src={profile.avatar}
            />
            {/* </RouterLink> */}
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              <Typography variant="h5" color="textSecondary">
                {profile.first_name} {profile.last_name}
              </Typography>
            </Link>
            <Typography variant="h6" color="textSecondary">
              {profile?.institution?.name || ''}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {profile.email}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Role{(profile?.roles?.length || 0) > 1 ? 's' : ''}:{' '}
              {profile.roles
                .map(role => role.name[0].toUpperCase() + role.name.slice(1))
                .join(', ')}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {sections(
            profile.roles.filter(role => role.name === 'admin').length > 0
          ).map(section => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        <Divider />
        {/* <Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box> */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;

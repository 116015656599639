import React from 'react';

const Logo = props => {
  const src = props.black ? '/static/logo-black.svg' : '/static/logo.svg';
  return (
    <img alt="Logo" src={src} style={{ height: 50, width: 50 }} {...props} />
  );
};

export default Logo;

import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer
} from 'react';
import SplashScreen from 'src/components/SplashScreen';
import axios from 'src/utils/axios';

import { useDispatch } from 'src/store';
import { setProfile } from 'src/slices/profile';
import { getTypes } from 'src/slices/types';
import { getInstitutions, getAwards } from 'src/slices/data';

const initialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  requestPasswordReset: () => Promise.resolve(),
  passwordReset: () => Promise.resolve()
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const dispatchAll = useDispatch();

  const getData = useCallback(
    (roles, institution) => {
      dispatchAll(setProfile());
      dispatchAll(getTypes());
      const isAdmin = roles.filter(role => role.name === 'admin').length > 0;
      dispatchAll(
        getInstitutions(isAdmin ? undefined : institution || undefined)
      );
      dispatchAll(getAwards());
    },
    [dispatchAll]
  );

  const login = async (email, password) => {
    const obj = {
      email,
      password
    };
    const response = await axios.post('api/auth/login', obj);
    const { roles, institution, pending_teacher } = response.data;
    if (!roles.includes('teacher') && !roles.includes('admin')) {
      await axios.get('api/auth/logout');
      if (pending_teacher) {
        return 'PENDING TEACHER';
      }
      return false;
    }
    let user = response.data;
    await getData(roles, institution);
    dispatch({
      type: 'LOGIN',
      payload: {
        user
      }
    });
    return true;
  };

  const logout = async () => {
    await axios.get('api/auth/logout');
    dispatch({ type: 'LOGOUT' });
  };

  const requestPasswordReset = async email => {
    await axios.post('/api/auth/forgotten', { email });
    dispatch({
      type: 'RESET'
    });
  };

  const passwordReset = async (password, password_confirm) => {
    await axios.post('/api/account/reset', {
      password,
      password_confirm
    });
    dispatch({
      type: 'RESET_REQUEST'
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const response = await axios.get('api/auth/profile');
        const loggedIn = response.data.data;
        if (loggedIn && loggedIn.id) {
          await getData(loggedIn.roles, loggedIn.institution);

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user: {}
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, [getData]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        // register,
        passwordReset,
        requestPasswordReset
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activitySearch: '',
};

const slice = createSlice({
  name: 'searches',
  initialState,
  reducers: {
    setActivitySearch(state, action) {
      state.activitySearch = action.payload;
    },
  }
});

export const reducer = slice.reducer;

export const setActivitySearch = (str = '') => async dispatch => {
  dispatch(slice.actions.setActivitySearch(str));
};

export default slice;

import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  id: undefined,
  email: '',
  first_name: '',
  last_name: '',
  phone: undefined,
  roles: [],
  volunteer_details: {
    dob: undefined,
    student_id: undefined,
    emergency_contact_name: '',
    emergency_contact_email: '',
    emergency_contact_phone: '',
    secondary_year: null,
    has_graduated: 0
  },
  ethnicities: [],
  teams: [],
  institution: {},
  available: {},
  skills: [],
  devices: [],
  bookmarked_activity_ids: [],
  upcoming_activity_ids: [],
  completed_activity_ids: [],
  managed_institutions: [],
  isAdmin: false
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfile(state, action) {
      const {
        id,
        email,
        first_name,
        last_name,
        roles,
        institution,
        managed_institutions
      } = action.payload;
      state.id = id;
      state.email = email;
      state.first_name = first_name;
      state.last_name = last_name;
      state.roles = roles || [];
      state.institution = institution || {};
      state.managed_institutions = managed_institutions || [];
      state.isAdmin = !!roles.find(role => role.name === 'admin');
    },
    logout(state) {
      state = initialState;
    }
  }
});

export const reducer = slice.reducer;

export const setProfile = () => async dispatch => {
  const response = await axios.get('api/auth/profile');
  dispatch(slice.actions.setProfile(response.data.data));
};

export const emptyProfile = () => dispatch => {
  dispatch(slice.actions.logout());
};

export const createEvent = data => async dispatch => {
  const response = await axios.post('/api/calendar/events/new', data);

  dispatch(slice.actions.createEvent(response.data));
};

export const selectEvent = eventId => async dispatch => {
  dispatch(slice.actions.selectEvent({ eventId }));
};

export const updateEvent = (eventId, update) => async dispatch => {
  const response = await axios.post('/api/calendar/events/update', {
    eventId,
    update
  });

  dispatch(slice.actions.updateEvent(response.data));
};

export const deleteEvent = eventId => async dispatch => {
  await axios.post('/api/calendar/events/remove', {
    eventId
  });

  dispatch(slice.actions.deleteEvent({ eventId }));
};

export const selectRange = (start, end) => dispatch => {
  dispatch(
    slice.actions.selectRange({
      start: start.getTime(),
      end: end.getTime()
    })
  );
};

export const openModal = () => dispatch => {
  dispatch(slice.actions.openModal());
};

export const closeModal = () => dispatch => {
  dispatch(slice.actions.closeModal());
};

export default slice;

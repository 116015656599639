import axios from 'axios';


const getBaseUrl = () => {

  const proto = process.env.REACT_APP_API_PROTOCOL ?? "https://";
  const subdomain = process.env.REACT_APP_API_SUBDOMAIN ?? false;
  const domain = process.env.REACT_APP_API_DOMAIN ?? window.location.hostname.replace(/^(staging-|preview-|testing-)?admin\./, "");

  const baseUrl = [
    proto,
    subdomain ? `${subdomain}.` : "",
    domain,
  ];

  return baseUrl.join("");
}

const axiosInstance = axios.create({
  withCredentials: true
});

axiosInstance.interceptors.request.use(
  async function(config) {
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };
    config.headers = headers;
    config.baseURL = getBaseUrl();
    return config;
  },
  function(error) {
    console.log('AXIOS Request Error');
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => response,
  error =>
    Promise.reject(
      axios.isCancel(error)
        ? 'Call was cancelled'
        : (error.response && error.response.data) || 'Something went wrong'
    )
);

export default axiosInstance;

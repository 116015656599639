import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as chatReducer } from 'src/slices/chat';
import { reducer as formReducer } from 'redux-form';
import { reducer as kanbanReducer } from 'src/slices/kanban';
import { reducer as mailReducer } from 'src/slices/mail';
import { reducer as notificationReducer } from 'src/slices/notification';
import { reducer as profileReducer } from 'src/slices/profile';
import { reducer as dataReducer } from 'src/slices/data';
import { reducer as typesReducer } from 'src/slices/types';
import { reducer as searchesReducer } from 'src/slices/searches';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  chat: chatReducer,
  form: formReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  notifications: notificationReducer,
  profile: profileReducer,
  data: dataReducer,
  types: typesReducer,
  searches: searchesReducer
});

export default rootReducer;

export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const INSTITUTION = 'School/Organisation';
export const INSTITUTIONS = 'Schools/Organisations';

export const ORGANISATION = 'Association';

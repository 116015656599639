import { createSlice } from '@reduxjs/toolkit';
import axios from 'src/utils/axios';

const initialState = {
  pendingTeams: [],
  institutions: {},
  users: [],
  nextUsers: 'api/users?page=1',
  countUsers: 0,
  activities: [],
  nextActivity: 'api/activities?page=1',
  countActivities: 0,
  teams: [],
  nextTeams: 'api/teams',
  countTeams: 0,
  last: {
    volunteers: undefined,
    active_volunteers: undefined,
    total_hours: undefined
  },
  pendingTeamsCount: undefined,
  awards: {}
};

const slice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    getLast(state, action) {
      state.last = action.payload.last;
      state.pendingTeamsCount = action.payload.pendingTeamsCount;
    },
    getInstitutions(state, action) {
      state.institutions = action.payload;
    },
    addUsers(state, actions) {
      state.nextUsers = actions.payload.nextUsers;
      state.users = actions.payload.users;
      state.countUsers = actions.payload.countUsers;
    },
    addActivities(state, actions) {
      state.nextActivity = actions.payload.nextActivity;
      state.activities = actions.payload.activities;
      state.countActivities = actions.payload.countActivities;
    },
    updateDataKey(state, actions) {
      state[actions.payload.key] = actions.payload.value;
    },
    addTeams(state, actions) {
      state.nextTeams = actions.payload.nextTeams;
      state.teams = actions.payload.teams;
      state.countTeams = actions.payload.countTeams;
    },
    logout(state) {
      state = initialState;
    },
    getAwards(state, actions) {
      state.awards = actions.payload;
    }
  }
});

export const reducer = slice.reducer;

export const getLast = (
  params = '',
  pendingTeamsDate = '',
  institution = undefined
) => async dispatch => {
  const filterTeams = !institution
    ? ''
    : `&filter[institution_id]=${institution?.id || ''}`;
  const request1 = axios.get(`api/dashboard/stats${params}`);
  const request2 = axios.get(
    `api/teams?filter[status]=pending${pendingTeamsDate}${filterTeams}`
  );
  const [response, responseTeams] = await Promise.all([request1, request2]);

  const obj = {
    last: response?.data || {},
    pendingTeamsCount: responseTeams?.data?.meta?.total
  };
  dispatch(slice.actions.getLast(obj));
};

export const updateDataKey = (key, value) => dispatch => {
  dispatch(slice.actions.updateDataKey({ key, value }));
};

export const emptyData = () => dispatch => {
  dispatch(slice.actions.logout());
};

export const getInstitutions = one => async dispatch => {
  const add = 'include=teams,notes,location_id,skills';
  let obj = {};
  const getOne = one && one.id;
  const response = await axios.get(
    `api/institutions${
      one && one.id ? `/${one.id}` : ''
    }?${add}&sort=name+asc`
  );
  const array = getOne ? [response.data.data] : response.data.data;
  array.forEach(element => {
    obj[element.id] = element;
  });

  dispatch(slice.actions.getInstitutions(obj));
};

export const setInstitutions = institutions => async dispatch => {
  dispatch(slice.actions.getInstitutions(institutions));
};

export const getAwards = () => async dispatch => {
  const url = 'api/awards?include=teams,skills,image_id';
  const response = await axios.get(url);
  const array = response?.data?.data || [];
  let obj = {};
  array.forEach(o => (obj[o.id] = o));
  dispatch(slice.actions.getAwards(obj));
};

export const getUsers = () => async dispatch => {
  let link = `/api/users?include=volunteer_details,skills,roles,issued_awards`;
  const response = await axios.get(link);
  const { next } = response.data.links;
  const { total: countUsers } = response.data.meta;
  dispatch(
    slice.actions.addUsers({
      users: response.data.data,
      nextUsers: next,
      countUsers
    })
  );
};

export const getMoreUsers = (nextUsers, prevUsers) => async dispatch => {
  if (!nextUsers) return;
  let link =
    nextUsers.replace('http://', 'https://') +
    `&include=volunteer_details,skills,roles,issued_awards`;
  const response = await axios.get(link);
  const { next } = response.data.links;
  const { total: countUsers } = response.data.meta;
  const users = [...(prevUsers || []), ...response.data.data];
  dispatch(
    slice.actions.addUsers({
      users,
      nextUsers: next,
      countUsers
    })
  );
};

export const getTeams = (isAdmin, id, locationId) => async dispatch => {
  if (!isAdmin && !id) {
    dispatch(slice.actions.addTeams({ teams: [], nextTeams: null }));
    return;
  }
  let link = `/api/teams?include=leader,institution_id,skills`;
  link += !id ? '' : `&filter[institution_id]=${id}`;
  link += !locationId ? '' : `&filter[location_id]=${locationId}`;
  const response = await axios.get(link);
  const { next } = response.data.links;
  const { total: countTeams } = response.data.meta;
  dispatch(
    slice.actions.addTeams({
      teams: response.data.data,
      nextTeams: next,
      countTeams
    })
  );
};

export const getMoreTeams = (nextTeams, prevTeams) => async dispatch => {
  if (!nextTeams) return;
  let link =
    nextTeams.replace('http://', 'https://') +
    '&include=leader,institution_id,skills';
  // link = isAdmin ? link : link + `&filter[institution_id]=${id}`;
  const response = await axios.get(link);
  const { next } = response.data.links;
  const { total: countTeams } = response.data.meta;
  const teams = [...(prevTeams || []), ...response.data.data];
  dispatch(
    slice.actions.addTeams({
      teams,
      nextTeams: next,
      countTeams
    })
  );
};

export const getMoreActivities = (
  nextActivity,
  prevActivities = []
) => async dispatch => {
  if (!nextActivity) return;
  let link = nextActivity.replace('http://', 'https://');
  const response = await axios.get(link);
  const { next } = response.data.links;
  const activities = [...prevActivities, ...response.data.data];
  dispatch(
    slice.actions.addActivities({
      activities,
      nextActivity: next,
      countActivities: response.data.meta.total
    })
  );
};

// export const selectEvent = eventId => async dispatch => {
//   dispatch(slice.actions.selectEvent({ eventId }));
// };

// export const updateEvent = (eventId, update) => async dispatch => {
//   const response = await axios.post('/api/calendar/events/update', {
//     eventId,
//     update
//   });

//   dispatch(slice.actions.updateEvent(response.data));
// };

// export const deleteEvent = eventId => async dispatch => {
//   await axios.post('/api/calendar/events/remove', {
//     eventId
//   });

//   dispatch(slice.actions.deleteEvent({ eventId }));
// };

// export const selectRange = (start, end) => dispatch => {
//   dispatch(
//     slice.actions.selectRange({
//       start: start.getTime(),
//       end: end.getTime()
//     })
//   );
// };

// export const openModal = () => dispatch => {
//   dispatch(slice.actions.openModal());
// };

// export const closeModal = () => dispatch => {
//   dispatch(slice.actions.closeModal());
// };

export default slice;
